import { useEffect, useRef, useState } from "react";
import ModelListItem from "../model-list.component/model-list-item";
import searchIcon from "../../assets/images/seqrops_search_icon.svg";
import "./home.scss";
import { useForm } from "react-hook-form";
import PaginationWrap from "../pagination/pagination-wrap";
import CreateInstancePopUp from "../../modules/asset-model-crud/create-instance/create-instance";
import { assetModelActions } from "../../store/asset-models/asset-models.action";
import debounce from "debounce";
import seqropsNoModelIcon from "assets/images/seqrops_no_model_found.svg";
import filterIcon from "assets/images/filter_icon.svg";
import filterChangedIcon from "assets/images/filter_outline_icon.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  setImageLogs,
  setVideoLogs,
} from "store/asset-models/asset-models.slice";
import { useAppDispatch } from "store";

interface IModelSearchForm {
  name: string;
}

function Home() {
  const { register, getValues, setValue } = useForm<IModelSearchForm>({
    defaultValues: { name: "" },
  });
  const [apiStatus, setApiStatus] = useState<boolean>(false);
  const [instancePopUpTrigger, setInstancepopUpTrigger] = useState(false);
  const [modelResponse, setModelResponse] = useState([]);
  const [isEquipment, setIsEquipment] = useState([]);
  const [selectedModelName, setSelectedModelName] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<any>();
  const dispatch = useAppDispatch();
  const [paginationPayload, setPaginationPayload] = useState({
    pageNo: 0,
    pageSize: 20,
    sortKey: "",
    sortOrder: "asc",
    totalRecords: 25,
  });
  const op = useRef<OverlayPanel>(null);
  //handle model click
  const handleModelClick = (modelName: string) => {
    setSelectedModelName(modelName);
    setInstancepopUpTrigger(true);
  };

  //handle search
  const handleSearch = (propertyValues: IModelSearchForm) => {
    if (propertyValues.name !== paginationPayload.sortKey) {
      setPaginationPayload({
        ...paginationPayload,
        sortKey: propertyValues.name,
      });
    }
  };
  // tableeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
  useEffect(() => {
    console.log(paginationPayload);

    assetModelActions
      .getAllModels(paginationPayload)
      .then((response: any) => {
        setModelResponse(response.data || []);
        setTotalRecords(response.totalRecords);
        // console.log("hii" ,response.data);

        // if (response.data.isEquipment === true) {
        //   setIsEquipment(response.data);
        // }
        setApiStatus(true);
      })
      .catch(() => {
        setModelResponse([]);
        setTotalRecords(0);
        setApiStatus(true);
      });
  }, [paginationPayload]);

  useEffect(() => {
    dispatch(setImageLogs([]));
    dispatch(setVideoLogs([]));
  }, []);

  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [filterImageChanged, setFilterImageChanged] = useState<boolean>(false);

  const handleFilterClick = (value: string) => {
    setSelectedFilter(value); // Update the selected filter option
    setFilterImageChanged(true); // Toggle the image change state to true
    const newPaginationPayload = {
      ...paginationPayload,
      sortKey: value,
    };
    setPaginationPayload(newPaginationPayload);
  };

  return (
    <>
      <div className="seqrops-home-wrapper ">
        <div className="container">
          <div className="seqrops-home-search-section d-flex">
            <input
              className="form-control"
              type="search"
              placeholder="Search Models"
              {...register("name", {
                pattern: /^\W+$/,
              })}
              onChange={debounce((e: any) => {
                setValue("name", e.target.value);
                handleSearch(getValues());
              }, 500)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleSearch(getValues());
                }
              }}
            />
            <button
              className="seqropsfilter"
              onClick={(e) => op.current && op.current?.toggle(e)}
            >
              <img
                className="filterImg"
                src={filterImageChanged ? filterIcon : filterChangedIcon}
                alt="seqrops filter icon"
              />
            </button>
            <OverlayPanel ref={op} className="overlayFilterNew">
              <div className="filterDropDown">
                <div
                  className="allDropDown"
                  onClick={() => handleFilterClick("")}
                >
                  <label>All</label>
                </div>
                <div
                  className="equipmentDropDown"
                  onClick={() => handleFilterClick("isEquipment")}
                >
                  <label>Equipment</label>
                </div>
              </div>
            </OverlayPanel>
          </div>
          <div className="d-flex seqrops-home-model-list-section flex-wrap">
            {modelResponse.map((model: any) => (
              <div
                className="seqrops-home-model-list my-2 px-2 qq"
                key={model.vertexName}
              >
                <ModelListItem
                  key={model.rid}
                  modelName={model.vertexName}
                  modelDescription={model.description}
                  modelIcon={model.icon}
                  modelIsEquipment={model.isEquipment}
                  modelClickTrigger={handleModelClick}
                  instanceCount={model.instanceCount}
                />
              </div>
            ))}

            {apiStatus && !totalRecords ? (
              <>
                <div className="seqrops-inital-page-wrapper">
                  <div className="container">
                    <div className="seqrops-no-model-image-section">
                      <img
                        src={seqropsNoModelIcon}
                        alt="seqrops no-model icon"
                      />
                    </div>
                    <div className="seqrops-no-model-image-text-section">
                      No Models Found
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <PaginationWrap
            paginationPayload={paginationPayload}
            setPaginationPayloadTrigger={setPaginationPayload}
            totalRecords={totalRecords}
          />
          <CreateInstancePopUp
            instancePopUp={instancePopUpTrigger}
            instancePopUpTrigger={setInstancepopUpTrigger}
            modelName={selectedModelName}
          ></CreateInstancePopUp>
        </div>
      </div>
    </>
  );
}

export default Home;
