import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "react-bootstrap";
import { useDialog } from "./DialogProvider";
import { useAppSelector } from "store";
import { setAddClicked, setDeleteClicked, setStaticSwitch } from "store/seqr-ops/seqr-ops.slice";
import { useDispatch } from "react-redux";

function ConfirmationDialog() {
  const [openDialog, closeDialog] = useDialog();
  const staticSwitch=useAppSelector((state)=>state.seqrOps.staticSwitch)
  const deleteClicked=useAppSelector((state)=>state.seqrOps.deleteClicked)
  const addClicked=useAppSelector((state)=>state.seqrOps.addClicked)

  const dispatch=useDispatch()
  const onOpenDialog = ({ handleClose }: { handleClose: () => void }) => {
    openDialog({
      children: (
        <>
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent>
            You have unsaved changes. Are you sure you want to continue without
            saving these changes?
          </DialogContent>
          <DialogActions>
          <div className="btn_unsaved">
              <button
                className="sequrops_btn_primary btn-outline btn"
                color="primary"
                onClick={() => {
                  if(staticSwitch===true){
                    dispatch(setStaticSwitch(false))
                  }
                  if(deleteClicked===true){
                    dispatch(setDeleteClicked(false))
                  }
                  if(addClicked===true){
                    dispatch(setAddClicked(false))
                  }
                  closeDialog();
                }}
              >
                Cancel
              </button>
              <button
                className="sequrops_btn_primary btn-primary btn me-2 "
                color="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                OK
              </button>
            </div>
          </DialogActions>
        </>
      ),
    });
  };

  return {
    onOpenDialog,
    closeDialog,
  };
}

export default ConfirmationDialog;
