import "./property-form.scss";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { assetInstancePropertiesActions } from "../../../store/instance-properties/instance-properties.action";
import { Panel } from "primereact/panel";
import { useDispatch } from "react-redux";
import DeleleFile from "modules/asset-model-crud/delete-file/delete-file";
import {
  IInstanceItemDetails,
  IInstanceItemProperty,
} from "store/asset-instance/asset-instance.interface";
import {
  FILE_TYPES,
  IFilePreview,
} from "modules/property-console/property-console.interface";
import StaticPropertyForm from "./static-property.form";
import DynamicPropertyForm from "./dynamic-property.form";

interface IPropertyForm {
  instance: IInstanceItemDetails;
  properties: IInstanceItemProperty[];

  register: any;
  setValue: any;
  clearErrors: any;
  errors: any;

  showStaticForm: boolean;
  filePreview: IFilePreview;
  handleFileChange: (filePreview: IFilePreview) => void;
  getValues: any;
}

function PropertyForm(props: IPropertyForm) {
  const [staticProperties, setStaticProperties] = useState<
    IInstanceItemProperty[]
  >([]);
  const [dynamicProperties, setDynamicProperties] = useState<
    IInstanceItemProperty[]
  >([]);

  const dispatch = useDispatch<any>();
  let deviceListReqBody: any = {};

  const [isDelete, setIsDelete] = useState(false);

  const handleFileDelete = () => {
    setIsDelete(false);
  };

  useEffect(() => {
    const setInitialValues = () => {
      let staticProps: IInstanceItemProperty[] = [];
      let dynamicProps: IInstanceItemProperty[] = [];
      let allProperties = props.properties
        .slice()
        .sort((a: { id: number }, b: { id: number }) => {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });

      if (props.showStaticForm) {
        staticProps = allProperties.filter(
          (x: IInstanceItemProperty) => x?.custom?.meta === null
        );
      } else {
        dynamicProps = allProperties.filter(
          (x: IInstanceItemProperty) => x?.custom?.meta !== null
        );
      }

      staticProps.forEach((property) => {
        if (FILE_TYPES.includes(property?.custom?.schema?.type)) {
          props.setValue("properties." + property?.name, undefined);
        } else if (property?.type === "BOOLEAN") {
          props.setValue(
            "properties." + property.name,
            props?.instance?.instance?.[property?.name] === "true"
              ? true
              : false
          );
        } else {
          props.setValue(
            "properties." + property.name,
            props.instance.instance?.[property.name] !== "null"
              ? props.instance.instance?.[property.name]
              : ""
          );
        }
      });
      dynamicProps.forEach((property) => {
        props.setValue(
          "properties." + property.name,
          props.instance.instance?.[property.name]
        );
      });
      setStaticProperties(staticProps);
      setDynamicProperties(dynamicProps);
    };
    setInitialValues();
  }, [props.instance, props.properties, props.showStaticForm]);

  useEffect(() => {
    deviceListReqBody["searchTerms"] = [];
    deviceListReqBody["page"] = 0;
    deviceListReqBody["limit"] = 10;
    deviceListReqBody["sortTerm"] = "name";
    deviceListReqBody["sortOrder"] = "ASC";

    dispatch(assetInstancePropertiesActions.getDeviceList(deviceListReqBody));
    dispatch(assetInstancePropertiesActions.getProtocolList());
  }, []);

  return (
    <>
      <div className="seqrops-property-form-wrapper">
        <div className="container-fluid p-0">
          <div className="d-flex justify-content-between border-seqrops-property-form">
            <div className="seqrops-property-adding-form-head-section">
              <p>Property</p>
              <div className="seqrops-property-type-title">
                {props.showStaticForm ? (
                  <span>static</span>
                ) : (
                  <span>dynamic</span>
                )}
              </div>
            </div>
          </div>
          {props.showStaticForm ? (
            <>
              <div className="seqrops-static-property-section">
                <StaticPropertyForm
                  register={props.register}
                  errors={props.errors}
                  instance={props.instance}
                  setValue={props.setValue}
                  clearErrors={props.clearErrors}
                  filePreview={props.filePreview}
                  handleFileChange={props.handleFileChange}
                  properties={staticProperties}
                />
              </div>
            </>
          ) : (
            <>
              <div className="seqrops-dynamic-property-section mx-0">
                <DynamicPropertyForm
                  instanceName={props.instance?.instance?.name || ""}
                  register={props.register}
                  errors={props.errors}
                  clearErrors={props.clearErrors}
                  setValue={props.setValue}
                  properties={dynamicProperties}
                  getValues={props.getValues}
                />
              </div>
            </>
          )}
        </div>
        <DeleleFile
          show={isDelete}
          hide={setIsDelete}
          deleteFileTrigger={handleFileDelete}
        />
      </div>
    </>
  );
}

export default PropertyForm;
