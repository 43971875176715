import { Table, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import viewIcon from "../../../assets/images/seqrops_view_icon.svg";
import deleteIcon from "../../../assets/images/seqrops_delete_icon.svg";
import "./media-logs.table.scss";
import downloadIcon from "../../../assets/images/seqrops-download-icon.svg";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { encryptUtils } from "utils/encryption.utils";
import { ILogHistory } from "components/instance-list.component/instance-list.interface";

function MediaLogsTable({
  isImageHistory,
  modelName,
  historyLogs,
  apiStatus,
  handleSearch,
  values,
  tokenDetailsEncrypted,
}: {
  isImageHistory:boolean;
  modelName: string;
  historyLogs: ILogHistory[];
  apiStatus: boolean;
  handleSearch: (values: any) => void;
  values: any;
  tokenDetailsEncrypted: string | null | undefined;
}) {
  // const confirmationPopup = DeleteConfirmationDialog();
  // const navigate = useNavigate();

  // const handleShowInstance = (id: string, name: string) => {
  //   const data = {
  //     id,
  //     instanceName: name,
  //     modelName: modelName,
  //   };
  //   const path = encryptUtils.encryptURL(data);
  //   navigate("/instance/" + path + "/?ut=" + `${tokenDetailsEncrypted}`);
  // };

  return (
    <>
      <div className="seqrops-instance-table-wrapper">
        <div className="container-fluid">
          <div className="seqrops-instance-table">
            <Table className="myTable">
              <thead>
                <tr>
                  <th>File Type</th>
                  <th>Type</th>
                  <th>Date and Time</th>
                  {isImageHistory && 
                  <th>OPERATOR IDENTIFIED</th>
                  }
                  <th>OPERATIONS</th>
                </tr>
              </thead>
              <tbody>
                {historyLogs.map((log: ILogHistory, index: any) => {
                  const timestamp = Number(log?.time);
                  const isValidDate = !isNaN(timestamp) && timestamp > 0;
                  const formattedTime = isValidDate
                    ? new Date(timestamp).toLocaleString()
                    : "Invalid Date";
                  return (
                    <tr key={index}>
                      <td>{log?.fileType}</td>
                      <td>{log?.type}</td>
                      <td>{formattedTime}</td>
                      {isImageHistory &&
                      <td>{log?.operatorRecognition}</td>
                      }
                      <td>
                        <a href={log.url} download className="media-link-tag">
                          <img
                            src={downloadIcon}
                            alt="Download"
                            className="media-download-icon"
                          />
                          {/* <span className="ml-2">
                            {log.type === "IMAGE"
                              ? "Download Image"
                              : "Download Video"}
                          </span> */}
                        </a>
                      </td>
                    </tr>
                  );
                })}
                {historyLogs.length === 0 ? (
                  <tr className="instance-no-result" key={"no-result"}>
                    <td colSpan={5}>No Result Found</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaLogsTable;
