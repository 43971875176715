import { AppThunk } from "store";
import { setLoading } from "./seqr-ops.slice";
import { COOKIE_DETAILS, THEME_CHANGE_URL } from "config/default.config";
import axios from "axios";
import { encryptUtils } from "utils/encryption.utils";
import { cookieUtils } from "utils/cookie.utils";

const setLoader = (loading: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(loading));
  };
};
const setIsDirty = (dirty: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setIsDirty(dirty));
  };
};
const setHasChange = (hasChange: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setHasChange(hasChange));
  };
};
const setDeleteClicked = (deleteClicked: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setDeleteClicked(deleteClicked));
  };
};
const setAddClicked = (addClicked: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAddClicked(addClicked));
  };
};
const setDynamicSwitch = (dynamicSwitch: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setDynamicSwitch(dynamicSwitch));
  };
};
const setStaticSwitch = (staticSwitch: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setStaticSwitch(staticSwitch));
  };
};
const setSwitchItem = (switchItem: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setSwitchItem(switchItem));
  };
};

const SetThemes = async (
  isDark: any,
  setReady: (status: boolean, isDark: boolean, session_id: string) => void
) => {
  let cookiedata = cookieUtils.getCookie(COOKIE_DETAILS.COOKIE_NAME)!;
  let decryptedCookieData = encryptUtils.decryptURL(cookiedata);

  const decryptedSessionId = encryptUtils.decryptURL(
    decryptedCookieData.sessionId
  );
  console.log("decryptedCookieData :", decryptedCookieData.sessionId);

  try {
    const requestData = {
      sessionId: decryptedCookieData.sessionId,
      // "Gw8nRM7ysKnPuAmPkilOa/4WtSV1LSX9OkHUJE6252Gym1ehSR0P6hEHahVMLUaV",
      code: isDark ? "DARK" : "LIGHT",
    };

    const response = await axios.post(
      THEME_CHANGE_URL.THEME_CHANGE,
      requestData
    );

    if (response.status === 204) {
      setReady(true, isDark, decryptedCookieData.sessionId);
    }

    return response.data;
  } catch (error: any) {
    console.error("Error:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    return 0;
  }
};
export const seqrOpsActions = {
  setLoader,
  setIsDirty,
  setHasChange,
  setDeleteClicked,
  setDynamicSwitch,
  setStaticSwitch,
  setSwitchItem,
  setAddClicked,
  SetThemes,
};
