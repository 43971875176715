import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssetModelSliceStates } from "./asset-model.interface";
import { ILogHistory } from "components/instance-list.component/instance-list.interface";

//initial state
const initialState: IAssetModelSliceStates = {
    publishErrorInstanceName: "",
    imageLogs: [],
    videoLogs: [],
  };

  const assetModelsSlice = createSlice({
    name: "assetModels",
    initialState: initialState,
    reducers: {
      setpublishErrorInstanceName: (
        state,
        action: PayloadAction<any>
      ) => {
        state.publishErrorInstanceName = action.payload;
      },
      setImageLogs: (
        state,
        action: PayloadAction<ILogHistory[]>
      ) => {
        state.imageLogs = action.payload;
      },
      setVideoLogs: (
        state,
        action: PayloadAction<ILogHistory[]>
      ) => {
        state.videoLogs = action.payload;
      },
    },
  });

  export const {
    setpublishErrorInstanceName,
    setImageLogs,
    setVideoLogs,
  } = assetModelsSlice.actions;
  export default assetModelsSlice.reducer;
  