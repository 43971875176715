import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISeqrOpsState } from "./seqr-ops.interface";

//initial state
const initialState: ISeqrOpsState = {
  loadingState: {
    loading: false,
  },
  loginState: {
    isLoggedIn: false,
  },
  navigationState: {},
  isDirty: false,
  hasChange: "",
  deleteClicked: false,
  dynamicSwitch: false,
  staticSwitch: false,
  switchItem: "",
  addClicked: false,
  theme: false,
};

const seqrOpsSlice = createSlice({
  name: "seqrOpsSlice",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingState = {
        ...state.loadingState,
        loading: action.payload,
      };
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loginState = {
        ...state.loginState,
        isLoggedIn: action.payload,
      };
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    setHasChange: (state, action: PayloadAction<string>) => {
      state.hasChange = action.payload;
    },
    setDeleteClicked: (state, action: PayloadAction<boolean>) => {
      state.deleteClicked = action.payload;
    },
    setAddClicked: (state, action: PayloadAction<boolean>) => {
      state.addClicked = action.payload;
    },
    setDynamicSwitch: (state, action: PayloadAction<boolean>) => {
      state.dynamicSwitch = action.payload;
    },
    setStaticSwitch: (state, action: PayloadAction<boolean>) => {
      state.staticSwitch = action.payload;
    },
    setSwitchItem: (state, action: PayloadAction<string>) => {
      state.switchItem = action.payload;
    },
    setTheme: (state, action: PayloadAction<boolean>) => {
      state.theme = action.payload;
    },
  },
});

export const {
  setLoading,
  setLogin,
  setIsDirty,
  setHasChange,
  setDeleteClicked,
  setDynamicSwitch,
  setStaticSwitch,
  setSwitchItem,
  setAddClicked,
  setTheme,
} = seqrOpsSlice.actions;
export default seqrOpsSlice.reducer;
