import "./dynamic-property.scss";
import { IInstanceItemProperty } from "store/asset-instance/asset-instance.interface";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { Panel } from "primereact/panel";
import {
  IDeviceDetail,
  IDynamicProperty,
  IUnit,
} from "modules/property-console/property-console.interface";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { log } from "console";
import axios from "axios";
import { ASSET_MANAGEMENT_API } from "config/default.config";
import { useDispatch, useSelector } from "react-redux";
import { assetInstancePropertiesActions } from "store/instance-properties/instance-properties.action";

const DynamicPropertyForm = ({
  instanceName,
  properties,
  register,
  errors,
  clearErrors,
  setValue,
  getValues,
}: {
  instanceName: string;
  properties: IInstanceItemProperty[];
  register: any;
  errors: any;
  clearErrors: any;
  setValue: any;
  getValues: any;
}) => {
  const devices = useAppSelector((state) => state.instanceProperties);
  const [values, setValues] = useState<any[]>([]);
  const [unitList, setunitList] = useState<IUnit[]>([]);
  const [deviceDetail, setDeviceDetail] = useState<IDeviceDetail[]>([]);
  useEffect(() => {
    console.log("properties :::::: ", properties);
  });
  const dispatch = useAppDispatch();
  const handleProtocolChange = (
    data: any,
    property: IInstanceItemProperty,
    index: number
  ) => {
    if (data === "ModbusTCP") {
      let IDeviceDetail: IDeviceDetail = {
        isModbusTCP: true,
        propertyId: property.id,
      };
      setDeviceDetail([...deviceDetail, IDeviceDetail]);
    } else {
      deviceDetail.forEach((detail: IDeviceDetail) => {
        if (detail.propertyId === property.id) {
          detail.isModbusTCP = false;
        }
        setValue(`dynamicProperties.${index}.ipAddress`, "");
        setValue(`dynamicProperties.${index}.port`, 0);
      });
    }
  };
  useEffect(() => {
    const formValues = getValues("dynamicProperties");
    const _values = [];

    for (let value of formValues) {
      const device = devices.dynamicProperties.filter(
        (x) => x.id === value.deviceId
      )?.[0];
      _values.push({
        ...value,
        device,
      });
    }
    setValues(_values);

    //setting modbusTCP props
    let tempDevices: IDeviceDetail[] = [];
    _values.forEach((property: any) => {
      if (
        String(property.port) !== "" &&
        String(property.port) !== "0" &&
        property.port !== 0
      ) {
        let deviceDetail: IDeviceDetail = {
          isModbusTCP: true,
          propertyId: property.propertyId,
        };
        tempDevices.push(deviceDetail);
      }
    });
    setDeviceDetail(tempDevices);
  }, [devices]);

  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return (
      <p className="error-message-section justify-content-start d-flex">
        {errorMsg}
      </p>
    );
  };

  // Storing Unit data in store

  useEffect(() => {
    axios
      .get(ASSET_MANAGEMENT_API.GET_STATIC_PROPERTY_UNIT, {
        params: {
          searchTerm: "",
          page: "0",
          limit: "100",
          sortType: "ASC",
          sortTerm: "name",
        },
      })
      .then((res) => {
        setunitList(res?.data?.result);
      })
      .catch((err) => console.log(err));
    dispatch(assetInstanceActions.fetchDynamicProperties());
  }, []);

  const dynamicUnitProperties = useSelector(
    (state: any) => state.assetInstances.dynamicUnitProperties
  );
  console.log("Dynamic Unit Properties:", dynamicUnitProperties);

  const unitPropertiesMap = dynamicUnitProperties?.reduce(
    (acc: any, property: any) => {
      acc[property.id] = property.name;
      return acc;
    },
    {}
  );

  return (
    <>
      {properties.map((property: IInstanceItemProperty, index: number) => {
        const unitid = property?.custom?.meta?.unitId;
        console.log("unitid ::: ", unitid);

        const unit = unitList?.find((unit) => unit.id === unitid);
        console.log("unitList ::: ", unit);

        const unitName = unit ? unit.name : "";
        console.log("deviceDetail : ", deviceDetail);
        console.log("propertymmm:::::::::", property);
        const unitId = property?.custom?.meta?.unitId;
        // const unitName = unitId ? unitPropertiesMap[unitId] : "";

        return (
          <Panel
            header={property.name}
            toggleable
            className="mb-4"
            key={property.id}
          >
            <div className="listDynamicData">
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Name </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.name}
                  />
                </div>
              </div>
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.description}
                  />
                </div>
              </div>

              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Control Mode </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.controlMode}
                  />
                </div>
              </div>
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>TagMode </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.tagMode}
                  />
                </div>
              </div>

              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Type</label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.type}
                  />
                </div>
              </div>
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Data Type </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.dataType}
                  />
                </div>
              </div>
              {(property?.custom?.meta?.type === "ANALOG" ||
                property?.custom?.meta?.type === "COMPUTED") && (
                <>
                  <div className="dynmicList d-flex">
                    <div className="form-group">
                      <label>Min Value </label>
                      <input
                        type="text"
                        readOnly
                        value={property?.custom?.meta?.minValue}
                      />
                    </div>
                  </div>
                  <div className="dynmicList d-flex">
                    <div className="form-group">
                      {" "}
                      <label>Max Value </label>
                      <input
                        type="text"
                        readOnly
                        value={property?.custom?.meta?.maxValue}
                      />
                    </div>
                  </div>
                </>
              )}
              {(property?.custom?.meta?.type === "ANALOG" ||
                property?.custom?.meta?.type === "COMPUTED") && (
                <>
                  <div className="dynmicList d-flex">
                    <div className="form-group">
                      <label>Unit</label>
                      <input type="text" readOnly value={unitName} />
                    </div>
                  </div>
                  <div className="dynmicList d-flex">
                    <div className="form-group">
                      <label>Conversion Factor Formula </label>
                      <input
                        type="text"
                        readOnly
                        value={property?.custom?.meta?.conversionFactorFormula}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {property?.custom?.meta?.type === "COMPUTED" && (
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Computed Formula </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.computedFormula}
                  />
                </div>
              </div>
            )}

            <div>
              {/* {renderDevice(index, property)}
              {renderProtocol(index, property)} */}
              {deviceDetail.map((detail: IDeviceDetail) => {
                if (detail.propertyId === property.id && detail.isModbusTCP) {
                  return (
                    <>
                      {/* {renderIPAddress(index, property)}
                      {renderPort(index, property)} */}
                    </>
                  );
                }
              })}
            </div>
          </Panel>
        );
      })}

      {properties.length === 0 ? (
        <p className="no-dynamic-property">
          No dynamic property for {instanceName}
        </p>
      ) : null}
    </>
  );
};

export default DynamicPropertyForm;
