import { IModelTreeBreadcrumbItem } from "components/model-tree.component/model-tree.types";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import closeBtn from "../../assets/images/sequrops_back_icon.svg";
import ModelingTree from "../../components/model-tree.component/model-tree";
import "./tree-console.scss";
import { encryptUtils } from "utils/encryption.utils";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { Tooltip } from "@mui/material";
import ConfirmationDialog from "components/dialogs/confirmation.dialog";
import { setIsDirty } from "store/seqr-ops/seqr-ops.slice";
import { ILogHistory } from "components/instance-list.component/instance-list.interface";

function TreeConsole({
  modelDetails,
  imageLog,
  videoLog,
  instanceId,
  modelName,
}: {
  modelDetails: any;
  imageLog: ILogHistory[];
  videoLog: ILogHistory[];
  instanceId: string;
  modelName: string;
}) {
  const dispatch = useAppDispatch();
  const [breadcrumbs, setBreadcrumbs] = useState<IModelTreeBreadcrumbItem[]>(
    []
  );
  const [modelDet, setModelDet] = useState<any>();
  const instanceMap = useAppSelector(
    (state) => state.assetInstances.instanceMap
  );

  const rootInstance: any = useAppSelector(
    (state) => state.assetInstances.rootInstance
  );

  const publishErrorInstanceName: any = useAppSelector(
    (state) => state.assetModels.publishErrorInstanceName
  );

  const isDirty = useAppSelector((state) => state.seqrOps.isDirty);

  const confirmationPopup=ConfirmationDialog();

  const navigate = useNavigate();

  //handling close
  const handleClose = () => {
    const data = { modelName };
    const details = encryptUtils.encryptURL(data);
    navigate("/model-instance/" + details);
  };

  useEffect(() => {

    const data = encryptUtils.decryptURL(modelDetails);

    let obj: any = {
      modelName : data?.modelName,
      instanceId: data?.id
    }
    setModelDet(obj);

    let reqBody = {
      rid: instanceId,
      modelName: modelName,
    };
    if (instanceId) {
      dispatch(assetInstanceActions.getInstanceHierarchy(reqBody));
      setBreadcrumbs([]);
    }
  }, [instanceId]);

  const renderBreadcrumb = () => {
    return (
      <>
        <div className="create_pagination px-0">
          {breadcrumbs.map((item: IModelTreeBreadcrumbItem, index: number) => {
            const bKey = index + "." + (item.vertexName || item.edgeName);
            return (
              <span key={bKey}>
                <b>
                  {item.isEdge
                    ? " / " + item.edgeName + " / "
                    : instanceMap[item.rid]?.name || item.name}
                </b>
              </span>
            );
          })}
        </div>
      </>
    );
  };

  const renderInstanceUpdationError = () => {
    return (
      <>
        <div className="instance-updation-error-section">
          <span>
            {/* <p className="error-message-section">{publishErrorInstanceName}</p> */}
            {publishErrorInstanceName ? (
              <p className="error-message-section1">
                {publishErrorInstanceName}
              </p>
            ) : (
              <p></p>
            )}
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="seqrops-tree-console-wrapper">
        <div className="container-fluid">
          <div className="seqrops-tree-console-title-section row">
            <div className="d-flex align-items-center p-0">
              <button className="seqrops-close-btn p-0 me-3">
                <div>
                  <Tooltip title="back">
                    <span
                      onClick={() => {
                        if (isDirty) {
                          console.log("dirty");
                          
                          confirmationPopup.onOpenDialog({
                            handleClose() {
                              confirmationPopup.closeDialog()
                              dispatch(setIsDirty(false))
                              handleClose();
                            }
                          })
                        } else{
                          handleClose()
                        }
                        console.log("in file");   
                      }
                      }
                    >
                      <img src={closeBtn} alt="" />
                    </span>
                  </Tooltip>
                </div>
              </button>

              <h5>{rootInstance.instance?.name || ""}</h5>
              {videoLog.length > 0 && (
                <button
                  className="sequrops_add_btn btn media-log-btn"
                  onClick={() => {
                    const data = {
                      id: modelDet?.id,
                      type: false,
                      instanceName: rootInstance.instance?.name,
                      modelName: modelDet?.modelName,
                    };
                    const mediaDetails = encryptUtils.encryptURL(data);
                    navigate("/media-logs/" + mediaDetails);
                    // navigate to video history
                  }}
                >
                  <span className="video-log-text">Video Logs</span>
                </button>
              )}
              {imageLog.length > 0 && (
                <button
                  className="sequrops_add_btn btn media-log-btn"
                  onClick={() => {
                    const data = {
                      id: modelDet?.id,
                      type: true,
                      instanceName: rootInstance.instance?.name,
                      modelName: modelDet?.modelName,
                    };
                    const mediaDetails = encryptUtils.encryptURL(data);
                    navigate("/media-logs/" + mediaDetails);
                    // navigate to image history
                  }}
                >
                  <span className="video-log-text">Image Logs</span>
                </button>
              )}
              {renderBreadcrumb()}
            </div>
          </div>
          {renderInstanceUpdationError()}
          <div className="row seqrops-tree-console-main">
            <ModelingTree
              currentModel={modelName}
              setBreadcrumbs={setBreadcrumbs}
            />
          </div>
        </div >
      </div >
    </>
  );
}

export default TreeConsole;
