import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./media-logs.list.scss";
import searchIcon from "../../../assets/images/seqrops_search_icon.svg";
import addIcon from "../../../assets/images/seqrops_add_btn.svg";
import backIcon from "../../../assets/images/sequrops_back_icon.svg";
import { Link, useParams } from "react-router-dom";
import { TablePagination, Tooltip } from "@mui/material";
import CreateInstancePopUp from "modules/asset-model-crud/create-instance/create-instance";
import debounce from "debounce";
import { IGetAllInstancePayload } from "store/asset-instance/asset-instance.interface";
import MediaLogsTable from "../media-logs-table.component/media-logs.table";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { encryptUtils } from "utils/encryption.utils";
import { useAppDispatch, useAppSelector } from "store";
import { ILogHistory } from "components/instance-list.component/instance-list.interface";
import { assetModelActions } from "store/asset-models/asset-models.action";


const initialPaginationData: IGetAllInstancePayload = {
  pageNo: 0,
  pageSize: 20,
  sortKey: "name",
  sortOrder: "asc",
  searchKey: "",
  modelName: "",
};
 
interface IInstanceSearchForm {
  name: string;
}

function MediaLogsList (props: any) {
  const { register, handleSubmit, setValue, getValues } =
    useForm<IInstanceSearchForm>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { mediaDetails } = useParams();
  const [path, setPath] = useState<any>();
  const [isImageHistory, setIsImageHistory]  = useState<boolean>(false);
  const [instanceResponse, setInstanceResponse] = useState([]);
  const [instancePayload, setInstancePayload] =
    useState<IGetAllInstancePayload>(initialPaginationData);
  const [instancePopUpTrigger, setInstancepopUpTrigger] = useState(false);
  const [historyLogs, setHistoryLogs] = useState<ILogHistory[]>([]);
  const dispatch = useAppDispatch();
  const imageMediaHistoryLogs = useAppSelector(
    (state) => state.assetModels.imageLogs
  );
  const videoMediaHistoryLogs = useAppSelector(
    (state) => state.assetModels.videoLogs
  );
  const [apiStatus, setApiStatus] = useState<boolean>(false);
  const [tokenDetailsEncrypted, setTokenDetailsEncrypted] = useState<string | null | undefined>();


  //handle row change
  const handleChangeRowsPerPage = (e: any) => {

    const params = {
      ...instancePayload,
      pageSize: e.target.value,
      pageNo:0
    };
    setInstancePayload(params);
    assetInstanceActions.getAllInstances(params).then((response: any) => {
      setInstanceResponse(response.data);
      setTotalRecords(response.totalRecords);
    });
  };

  //handling pageNo change
  const handlePageNoChange = (event: any, value: number) => {
    const params = {
      ...instancePayload,
      pageNo: value,
    };
    setInstancePayload(params);
    // assetInstanceActions.getAllInstances(params).then((response: any) => {
    //   setInstanceResponse(response.data);
    //   setTotalRecords(response.totalRecords);
    // });
  };

  //handle search
  const handleSearch = (propertyValues: IInstanceSearchForm) => {
    const params = {
      ...initialPaginationData,
      modelName: instancePayload.modelName,
      searchKey: propertyValues.name,
    };
    setInstancePayload(params);
    // assetInstanceActions.getAllInstances(params).then((response: any) => {
    //   setInstanceResponse(response.data);
    //   setTotalRecords(response.totalRecords);
    // });
  };

  const handleNavigateBack = ()=>{
    setHistoryLogs([]);
  }


  // const getDeviceMediaLogs = async (modelName: string) => {
  //   dispatch(await assetModelActions.getDeviceImei(modelName));
  // };
  
  useEffect(() => {
    const datas = encryptUtils.decryptURL(mediaDetails);
    setIsImageHistory(datas?.type);
    // getDeviceMediaLogs(data.modelName);
    const data = {
      id: datas?.id,
      instanceName: datas?.instanceName,
      modelName: datas?.modelName,
    };
    setPath(encryptUtils.encryptURL(data));


    if (datas?.type) {
      setHistoryLogs(imageMediaHistoryLogs);
    }else{
      setHistoryLogs(videoMediaHistoryLogs);
    }

    if (datas) {
      const payload = {
        ...initialPaginationData,
        modelName: datas.modelName,
      };
      setInstancePayload({
        ...payload,
      });

      
      // assetInstanceActions
      //   .getAllInstances(payload)
      //   .then((response: any) => {
      //     setInstanceResponse(response.data);
      //     setTotalRecords(response.totalRecords);
      //     setApiStatus(true);
      //   })
      //   .catch(() => {
      //     setInstanceResponse([]);
      //     setTotalRecords(0);
      //     setApiStatus(true);
      //   });
    }
  }, [mediaDetails]);

  useEffect(() => {

    const data = encryptUtils.decryptURL(mediaDetails);
    if (data?.type) {
      setHistoryLogs(imageMediaHistoryLogs);
    }else{
      setHistoryLogs(videoMediaHistoryLogs);
    }
  }, [imageMediaHistoryLogs, videoMediaHistoryLogs]);

  return (
    <>
      <div className="seqrops-instance-list-wrapper">
        <div className="container">
          <div className="seqrops-instance-head-section d-flex p-4 justify-content-between">
            <div className="seqrops-instance-title-section d-flex align-items-center">
              <div className="me-2">
                <Tooltip title="back">
                  <Link to={'/instance/' + path + "/?ut="+`${tokenDetailsEncrypted}`} className="p-2">
                    <img src={backIcon} alt="back" onClick={()=>{handleNavigateBack()}}/>
                  </Link>
                </Tooltip>
              </div>

              <p className="model-name-section">{instancePayload.modelName}</p>
              <p>&nbsp;{isImageHistory ? "Image Logs" : "Video Logs" }</p>
            </div>
            <div className="seqrops-instance-search-section d-flex align-items-center">
              <input
                className="form-control"
                type="search"
                placeholder={isImageHistory ? "Search Image" : "Search Videos"}
                {...register("name", {
                  pattern: /^[a-zA-Z0-9_]+$/,
                })}
                onChange={debounce((e: any) => {
                  setValue("name", e.target.value);
                  handleSearch(getValues());
                }, 500)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    handleSearch(getValues());
                  }
                }}
              />
              <button
                onClick={handleSubmit(handleSearch)}
                className="search_btn"
              >
                <img src={searchIcon} alt="seqrops search icon" />
              </button>
              <button
                className="sequrops_add_btn btn btn-fill"
                onClick={() => {
                  setInstancepopUpTrigger(true);
                }}
              >
              </button>
            </div>
          </div>
          <div className="seqrops-instance-table-section container">
            <MediaLogsTable
              isImageHistory={isImageHistory}
              modelName={instancePayload.modelName}
              historyLogs={historyLogs}
              apiStatus={apiStatus}
              handleSearch={handleSearch}
              values={getValues()}
              tokenDetailsEncrypted={tokenDetailsEncrypted}
            />
            {/* <TablePagination
              component="div"
              count={totalRecords > 0 ? totalRecords : 0}
              rowsPerPageOptions={totalRecords > 0 ? [5, 10, 15, 20, 25] : []}
              page={totalRecords > 0 ? instancePayload.pageNo : 0}
              onPageChange={handlePageNoChange}
              rowsPerPage={totalRecords > 0 ? instancePayload.pageSize : 0}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <CreateInstancePopUp
              instancePopUp={instancePopUpTrigger}
              instancePopUpTrigger={() => {
                setInstancepopUpTrigger(false);
                handleSearch(getValues());
              }}
              modelName={instancePayload.modelName}
            ></CreateInstancePopUp>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaLogsList;
