import axios from "axios";
import { ASSET_MANAGEMENT_API } from "../../config/default.config";
import { IEquipmentIMEIGetModel, getAllModelsPayload } from "./asset-model.interface";

const fetchAllModels = async (getAllModelsPayload: getAllModelsPayload) => {
  
    const response = await axios.post(ASSET_MANAGEMENT_API.GET_ALL_MODELS, getAllModelsPayload);
    return response.data;
  };

  const getDeviceImei = async (modelName: string, equipmentDetailas: IEquipmentIMEIGetModel) => {
  
    const response = await axios.post(ASSET_MANAGEMENT_API.GET_DEVICE_BY_MODEL_NAME.replace(':equipmentModelName', modelName), equipmentDetailas);
    return response.data;
  };

  const getImageLogs = async (imei: string) => {
  
    const response = await axios.get(ASSET_MANAGEMENT_API.GET_MEDIA_LOGS.replace(':imei', imei).replace(':type', "IMAGE"));
    return response.data;
  };

  const getVideoLogs = async (imei: string) => {
  
    const response = await axios.get(ASSET_MANAGEMENT_API.GET_MEDIA_LOGS.replace(':imei', imei).replace(':type', "VIDEO"));
    return response.data;
  };

  export const assetModelService = { 
    fetchAllModels,
    getDeviceImei,
    getImageLogs,
    getVideoLogs,
  };