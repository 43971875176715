import { Table, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import viewIcon from "../../assets/images/seqrops_view_icon.svg";
import deleteIcon from "../../assets/images/seqrops_delete_icon.svg";
import { encryptUtils } from "../../utils/encryption.utils";
import "./instance-table.scss";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { IInstanceDeleteEvent } from "components/model-tree.component/model-tree.types";
import moment from "moment";

function InstanceTable({
  modelName,
  instances,
  apiStatus,
  handleSearch,
  values,
  tokenDetailsEncrypted,
}: {
  modelName: string;
  instances: any[];
  apiStatus: boolean;
  handleSearch: (values: any) => void;
  values: any;
  tokenDetailsEncrypted: string | null | undefined;
}) {
  const confirmationPopup = DeleteConfirmationDialog();
  const navigate = useNavigate();

  const handleShowInstance = (id: string, name: string) => {
    const data = {
      id,
      instanceName: name,
      modelName: modelName,
    };
    const path = encryptUtils.encryptURL(data);
    navigate("/instance/" + path + "/?ut=" + `${tokenDetailsEncrypted}`);
  };

  const delInstance = async (data: IInstanceDeleteEvent) => {
    confirmationPopup.closeDialog();
    const deleteResponse = await assetInstanceActions.deleteInstance(data);
    if (deleteResponse) {
      handleSearch(values);
    }
  };

  const handleDeleteInstance = (instanceId: string, instanceName: string) => {
    let data: IInstanceDeleteEvent = {
      sourceId: instanceId,
      sourceModel: modelName,
      targetId: "__EMPTY_VALUE__",
      targetModel: "__EMPTY_VALUE__",
      edgeName: "__EMPTY_VALUE__",
      isRootNode: true,
      instanceName: instanceName,
    };
    confirmationPopup.onOpenDialog({
      title: "Delete Confirmation",
      message: "Are you sure want to delete the instance?",
      cancelBtn: "No",
      successBtn: "Yes",
      handleClose: async () => {
        await delInstance(data);
      },
    });
  };

  return (
    <>
      <div className="seqrops-instance-table-wrapper">
        <div className="container-fluid">
          <div className="seqrops-instance-table">
            <Table className="myTable">
              <thead>
                <tr className="seqrops-instance-table-first-tr">
                  <th>Name</th>
                  <th>Description</th>
                  <th>Created On</th>
                  <th>Updated On</th>
                  <th>Operations</th>
                </tr>
              </thead>
              <tbody>
                {instances?.map((instance: any) => {
                  return (
                    <tr key={instance?.id}>
                      <td>{instance?.instanceName}</td>
                      <td>{instance?.description}</td>
                      <td>
                        {moment
                          .utc(instance?.createdAt)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        {moment
                          .utc(instance?.updatedAt)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td className="operations">
                        <Tooltip title="View">
                          <span
                            className="seqrops-view-row"
                            onClick={() => {
                              handleShowInstance(
                                instance?.id,
                                instance?.instanceName
                              );
                            }}
                          >
                            <img src={viewIcon} alt="seqrops-viewIcon" />
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <span
                            className="seqrops-view-row"
                            onClick={() => {
                              handleDeleteInstance(
                                instance?.id,
                                instance?.instanceName
                              );
                            }}
                          >
                            <img src={deleteIcon} alt="seqrops-viewIcon" />
                          </span>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
                {apiStatus && instances?.length === 0 ? (
                  <tr className="instance-no-result" key={"no-result"}>
                    <td colSpan={5}>No Result Found</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstanceTable;
