import { ASSET_MANAGEMENT_API, MESSAGES } from "../../config/default.config";
import { toasterUtils } from "../../utils/toaster.utils";
import {
  getAssosiationsPayload,
  createEdgeRelationPayload,
  createInstancePayload,
  publishPayload,
  IInstanceItemDetails,
  IGetAllInstancePayload,
} from "./asset-instance.interface";
import { assetInstanceService } from "./asset-instance.service";
import { AppThunk } from "..";
import {
  setInstance,
  setInstanceDynamicProperties,
  setInstanceHierarchy,
  setInstanceStaticProperties,
  setModelAssosiations,
} from "./asset-instance.slice";
import { UseFormSetError } from "react-hook-form";
import { ICreateInstanceForm } from "modules/asset-model-crud/create-instance/create-instance.interface";
import { IInstanceDeleteEvent } from "components/model-tree.component/model-tree.types";
import { setpublishErrorInstanceName } from "store/asset-models/asset-models.slice";
import axios from "axios";
import { Dispatch } from "redux";
import { Console } from "console";
const showError = (
  errorCode: string,
  setError: UseFormSetError<ICreateInstanceForm> | undefined
) => {
  let errorMessage: string = "";
  let fieldName: "name" | "description" | undefined = undefined;

  if (errorCode === "INSTANCE_NAME_DUPLICATE") {
    fieldName = "name";
    errorMessage = MESSAGES.INSTANCE_NAME_EXISTS;
  } else if (errorCode == "RELATION_MAX_REACHED") {
    errorMessage = MESSAGES.RELATION_MAX_REACHED;
  }

  if (fieldName && setError) {
    setError(fieldName, { type: "custom", message: errorMessage });
  } else {
    toasterUtils.showError(errorMessage);
  }
};

const createInstance = async (
  instanceDetails: createInstancePayload,
  setError: UseFormSetError<ICreateInstanceForm> | undefined,
  setSuccess: (state: boolean) => void
) => {
  try {
    console.log("instance payload :::: ", instanceDetails);
    const response = await assetInstanceService.createInstance(instanceDetails);
    if (response.data.success) {
      toasterUtils.showSuccess(MESSAGES.INSTANCE_CREATE_SUCCESS);
      setSuccess(true);
    } else {
      showError(response?.data?.errorCode, setError);
      setSuccess(false);
    }
  } catch (error: any) {
    showError(error?.response?.data?.errorCode, setError);
    setSuccess(false);
  }
};

const getAllInstances = async (instancePayload: IGetAllInstancePayload) => {
  try {
    const response = await assetInstanceService.getAllInstances(
      instancePayload
    );
    if (response.success) {
      return response.data;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getInstanceHierarchy = (getInstanceHierarchyPayload: any): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetInstanceService.fetchInstanceHierarchy(
      getInstanceHierarchyPayload
    );
    if (response.success) {
      dispatch(setInstanceHierarchy([...response.data.data]));
      dispatch(setInstanceStaticProperties(response.data.properties));
      dispatch(setInstance(response.data.data[0]));
      return response;
    }
  };
};

const getInstanceDetails = async (getInstanceHierarchyPayload: any) => {
  const response = await assetInstanceService.fetchInstanceHierarchy(
    getInstanceHierarchyPayload
  );
  if (response?.success) {
    return response;
  }
  return null;
};

const getSubInstanceHierarchy = async (
  getInstanceHierarchyPayload: any
): Promise<AppThunk> => {
  const response = await assetInstanceService.fetchInstanceHierarchy(
    getInstanceHierarchyPayload
  );
  return response?.data;
};

const getAssosiations = (
  getAssosiationsPayload: getAssosiationsPayload
): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetInstanceService.getAssociations(
      getAssosiationsPayload
    );
    if (response.success) {
      dispatch(setModelAssosiations(response.data || []));
    }
  };
};

const createEdgeRelation = async (
  createEdgeRelationPayload: createEdgeRelationPayload,
  setError: any,
  setSuccess: any
) => {
  try {
    const response = await assetInstanceService.createEdgeRelation(
      createEdgeRelationPayload
    );
    if (response.success) {
      toasterUtils.showSuccess(MESSAGES.ASSOSIATION_CREATED_SUCCESFULLY);
      setSuccess(true);
    } else {
      setSuccess(false);
      toasterUtils.showError(MESSAGES.ASSOSIATION_CREATED_FAILED);
    }
  } catch (error: any) {
    if (
      error.response.data.errorCode === MESSAGES.DUPLICATE_ASSOCIATION_ERRORCODE
    ) {
      setSuccess(false);
      let fieldName: any = "associationTarget";
      setError(fieldName, {
        type: "custom",
        message: MESSAGES.DUPLICATE_ASSOCIATION_MESSAGE,
      });
    }
  }
};

const publishInstance = (
  publishPayload: publishPayload,
  modelName: string,
  instance: string,
  setError: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      try {
        const device_response = await assetInstanceService.getDeviceConnected(
          instance,
          modelName
        );
        if (device_response?.data?.count > 0) {
          const response = await assetInstanceService.publishInstance(
            publishPayload
          );
          if (response.data.success) {
            toasterUtils.showSuccess(MESSAGES.PUBLISH_SUCCESS);
          } else {
            const instance: IInstanceItemDetails | undefined =
              response?.data?.data?.instance || undefined;
            let message = "";
            if (
              response?.data?.errorCode === "DYNAMIC_PROP_VERIFICATION_ERROR" &&
              instance
            ) {
              message =
                "Dynamic property verification failed on " +
                instance.instance.name +
                " at " +
                instance?.vertexName;
            }
            if (
              response?.data?.errorCode === "STATIC_PROP_VERIFICATION_ERROR" &&
              instance
            ) {
              message =
                "Static property verification failed on " +
                instance.instance.name +
                " at " +
                instance?.vertexName;
            }
            dispatch(setpublishErrorInstanceName(message));
            toasterUtils.showError(MESSAGES.PUBLISH_FAILED);
          }
        } else {
          let message =
            "Device not connected for this instance. Please Connect device";
          dispatch(setpublishErrorInstanceName(message));
          toasterUtils.showError(MESSAGES.PUBLISH_FAILED);
        }
        console.log(device_response);
      } catch (error: any) {
        console.log(error);
      }
    } catch (error: any) {
      let errorCode = error?.response.data.errorCode;
      let message = "";

      const instance: IInstanceItemDetails | undefined =
        error?.response?.data?.data?.instance || undefined;
      console.log("instance ::: ", instance);

      if (errorCode === "DYNAMIC_PROP_VERIFICATION_ERROR" && instance) {
        message =
          "Dynamic property verification failed on " +
          instance.instance.name +
          " at " +
          instance?.vertexName;
      }
      if (errorCode === "STATIC_PROP_VERIFICATION_ERROR" && instance) {
        message =
          "Static property verification failed on " +
          instance.instance.name +
          " at " +
          instance?.vertexName;
      }
      dispatch(setpublishErrorInstanceName(message));
      toasterUtils.showError(MESSAGES.PUBLISH_FAILED);
    }
  };
};

const deleteInstance = async (data: IInstanceDeleteEvent) => {
  try {
    const response = await assetInstanceService.deleteInstance(data);
    if (response.success) {
      toasterUtils.showSuccess(MESSAGES.INSTANCE_DELETE_SUCCESS);
      return true;
    } else {
      toasterUtils.showError(MESSAGES.INSTANCE_DELETE_FAILED);
      return false;
    }
  } catch (error) {
    toasterUtils.showError(MESSAGES.INSTANCE_DELETE_FAILED);
    return false;
  }
};

// const getAllUnit = async () => {
//   try {
//     const response = await assetInstanceService.fetchAllUnit();
//     console.log("action ::: ", response);
//     return response;
//   } catch (err) {
//     console.log(err);
//   }
// };

// asset-instance.action.js

//

const fetchDynamicProperties = (): AppThunk => {
  return async (dispatch, getState) => {
    axios
      .get(ASSET_MANAGEMENT_API.GET_DYNAMIC_PROPERTY_UNIT, {
        params: {
          searchTerm: "",
          page: "0",
          limit: "100",
          sortType: "ASC",
          sortTerm: "name",
        },
      })
      .then((res) => {
        dispatch(setInstanceDynamicProperties(res?.data?.result));
      })
      .catch((err) => console.log(err));
  };
};

export const assetInstanceActions = {
  createInstance,
  getAllInstances,
  getAssosiations,
  createEdgeRelation,
  publishInstance,
  getInstanceHierarchy,
  getSubInstanceHierarchy,
  deleteInstance,
  getInstanceDetails,
  fetchDynamicProperties,
  //  getAllUnit,
};
