import { toasterUtils } from "utils/toaster.utils";
import { IEquipmentIMEIGetModel, getAllModelsPayload } from "./asset-model.interface";
import { assetModelService } from "./asset-models.service";
import { ASSET_MANAGEMENT_API, ERROR_CODES, MESSAGES } from "config/default.config";
import { AppThunk } from "store";
import { ILogHistory } from "components/instance-list.component/instance-list.interface";
import { type } from "os";
import { setImageLogs, setVideoLogs } from "./asset-models.slice";

const getAllModels = async (getAllModelsPayload: getAllModelsPayload) => {
  try {
    const response = await assetModelService.fetchAllModels(
      getAllModelsPayload
    );

    if (response.success) {
      return response.data;
    } else {
      return 0;
    }
  } catch (error: any) {
    if (error.response.data.errorCode === ERROR_CODES.SCHEMA_DB_NOT_FOUND) {
      toasterUtils.showError(MESSAGES.SCHEMA_DB_NOT_FOUND_MESSAGE);
    }
    return 0;
  }
};

const getDeviceImei = (modelName: string, equipmentId: string): AppThunk => {
  return async (dispatch, getState) => {
    try {

      let equipmentDetails : IEquipmentIMEIGetModel = {
        equipmentId : equipmentId
      }

      const response = await assetModelService.getDeviceImei(modelName, equipmentDetails);
      if (response?.success) {
          dispatch(getImageLogs(response?.imei?.toString()))
          dispatch(getVideoLogs(response?.imei?.toString()))
      }else if(!response?.success){
        dispatch(setImageLogs([]));
        dispatch(setVideoLogs([]));
      }
    } catch (error: any) {
      return 0;
    }
  };
};

const getImageLogs = (imei: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.getImageLogs(imei);
      console.log("image logs ::::: ", response);
      if (response?.success) {

        if(response?.data?.length === 0){
          dispatch(setImageLogs([]));
        }

        let imageHistoryLogList : ILogHistory[] = [];

        response?.data?.map((imageHistoryItem: any) =>{
          let imageLog: ILogHistory = {
            fileType : imageHistoryItem?.fileType,
            type :imageHistoryItem?.type,
            time : imageHistoryItem?.time,
            url : ASSET_MANAGEMENT_API.GET_MEDIA_DOWNLOADED.replace(':mediaName',imageHistoryItem?.name).replace(':mediaType','IMAGE'),
            operatorRecognition: imageHistoryItem?.operator_recognition,
          }
          imageHistoryLogList.push(imageLog);
        })

        dispatch(setImageLogs(imageHistoryLogList));
      }else if(!response?.success){
        dispatch(setImageLogs([]));
      }
    } catch (error: any) {
      return 0;
    }
  };
};

const getVideoLogs = (imei: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await assetModelService.getVideoLogs(imei);
      console.log("video logs ::::: ", response);
      if (response?.success) {
        let videoHistoryLogList : ILogHistory[] = [];

        if(response?.data?.length === 0){
          dispatch(setVideoLogs([]));
        }

        response?.data?.map((videoHistoryItem: any) =>{
          let videoLog: ILogHistory = {
            fileType : videoHistoryItem?.fileType,
            type :videoHistoryItem?.type,
            time : videoHistoryItem?.time,
            url : ASSET_MANAGEMENT_API.GET_MEDIA_DOWNLOADED.replace(':mediaName',videoHistoryItem?.name).replace(':mediaType','VIDEO'),
            operatorRecognition: "",
          }
          videoHistoryLogList.push(videoLog);
        })

        dispatch(setVideoLogs(videoHistoryLogList));
      }else if(!response?.success){
        dispatch(setVideoLogs([]));
      }
    } catch (error: any) {
      return 0;
    }
  };
};

export const assetModelActions = {
  getAllModels,
  getDeviceImei,
  getImageLogs,
  getVideoLogs,
};
