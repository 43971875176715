import { useEffect, useState } from "react";
import { useForm, FieldErrors } from "react-hook-form";
import PropertyForm from "../../components/forms/property-form/property-form";
import { useAppDispatch, useAppSelector } from "../../store";
import { assetInstanceActions } from "../../store/asset-instance/asset-instance.action";
import {
  IInstanceItemProperty,
  publishPayload,
} from "../../store/asset-instance/asset-instance.interface";
import { assetInstancePropertiesActions } from "../../store/instance-properties/instance-properties.action";
import {
  IDynamicPropertySavePayload,
  IFileSavePayload,
  IPropertySavePayload,
} from "../../store/instance-properties/instance-properties.interface";
import "./property-console.scss";
import { MESSAGES } from "config/default.config";
import { ICreateInstanceForm } from "modules/asset-model-crud/create-instance/create-instance.interface";
import {
  FILE_MIME_TYPES,
  FILE_TYPES,
  IDynamicProperty,
  IDynamicPropertyFormItem,
  IFilePreview,
  IPropertyConsoleForm,
  IValidationRequest,
  NUMERIC_TYPES,
} from "./property-console.interface";
import { setInstance } from "store/asset-instance/asset-instance.slice";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import { setpublishErrorInstanceName } from "store/asset-models/asset-models.slice";
import moment from "moment";
import {
  setDynamicSwitch,
  setIsDirty,
  setStaticSwitch,
  setSwitchItem,
} from "store/seqr-ops/seqr-ops.slice";

function PropertyConsole() {
  const rootInstance = useAppSelector((state) => state.assetInstances.instance);
  const instance = useAppSelector((state) => state.assetInstances.instance);
  const instanceProperties = useAppSelector(
    (state) => state.assetInstances.properties
  );

  const [properties, setProperties] = useState<IInstanceItemProperty[]>([]);
  const [filePreview, setFilePreview] = useState<IFilePreview>({});
  const [showStaticForm, setShowStaticForm] = useState<string>("STATIC");
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState,
    getValues,
  } = useForm<IPropertyConsoleForm>({
    defaultValues: {
      properties: {},
      dynamicProperties: [],
    },
  });

  // Dirty Handling---> variable declaration
  const staticSwitch = useAppSelector((state) => state.seqrOps.staticSwitch);
  const dynamicSwitch = useAppSelector((state) => state.seqrOps.dynamicSwitch);
  const isDirty = useAppSelector((state) => state.seqrOps.isDirty);
  const switchItem = useAppSelector((state) => state.seqrOps.switchItem);

  const [dynamicPropertyFlag, setDynamicPropertyFlag] = useState(false);
  const [StaticForm, setStaticForm] = useState<boolean>(false);
  const [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);

  const errors: FieldErrors<ICreateInstanceForm> = formState.errors;
  const updateFilePreview = (
    selectedInstanceProperty: IInstanceItemProperty[]
  ) => {
    const _filePreview: IFilePreview = {};
    const _selectedInstanceProperty = selectedInstanceProperty
      .slice()
      .sort((a: { id: number }, b: { id: number }) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
    const deviceDefaultValues: IDynamicPropertyFormItem[] = [];
    _selectedInstanceProperty.forEach((property, index) => {
      if (FILE_TYPES.includes(property?.custom?.schema?.type)) {
        /** */
        const defaultValue = instance.instance[property.name];
        const attachment = instance.instance.__ATTACHMENT__?.find(
          (x: {
            instanceId: string;
            propertyName: string;
            reference: string;
            vertexName: string;
          }) => x.propertyName === property.name
        );

        _filePreview[property.name] = {
          fileURL: defaultValue,
          rid: attachment?.["@rid"] || null,
          ref: attachment?.reference || "",
          isDeleted: false,
          isChanged: false,
          file: {
            name: property.name,
            file: undefined,
            type: property.type,
            instanceId: instance.rid,
            schemaType: property?.custom?.schema?.type,
            isMandatory: property?.custom?.schema?.mandatory === "true",
          },
        };
      }

      if (property?.custom?.meta != null) {
        const device = instance.instance.__DEVICE__?.find(
          (x: {
            ["@rid"]: string;
            deviceId: number;
            propertyName: string;
            ipAddress: string;
            protocol: string;
          }) => x.propertyName === property.name
        );

        deviceDefaultValues.push({
          rid: device?.["@rid"] || null,
          deviceId: device?.deviceId || 0,
          ipAddress: device?.ipAddress || "",
          protocol: device?.protocol || "",
          port: device?.port || 0,
          propertyName: property.name,
          propertyId: property.id,
        });
      }
    });

    setFilePreview(_filePreview);
    setValue("dynamicProperties", deviceDefaultValues);
  };

  const handleFileChange = (_filePreview: IFilePreview) => {
    setFilePreview({ ..._filePreview });
  };

  useEffect(() => {
    //restoring publish error
    let message: string = "";
    dispatch(setpublishErrorInstanceName(message));

    if (!instance.isEdge && instance.vertexName) {
      setProperties(instanceProperties[instance.vertexName] || []);
      updateFilePreview(instanceProperties[instance.vertexName] || []);
    }
  }, [instance, instanceProperties]);

  const setSuccess = async () => {
    dispatch(seqrOpsActions.setLoader(false));
    let reqBody = {
      rid: instance.rid,
      modelName: instance.vertexName,
    };
    const updated = await assetInstanceActions.getInstanceDetails(reqBody);
    if (updated) {
      dispatch(setInstance({ ...updated.data.data[0], edges: [] }));
      reset();
    }
    setIsDisabled(false);
  };

  //handling save
  const handleSave = async (propertyValues: IPropertyConsoleForm) => {
    setIsDisabled(true);
    //restoring publish error
    let message: string = "";
    dispatch(setpublishErrorInstanceName(message));

    if (showStaticForm === "STATIC") {
      dispatch(seqrOpsActions.setLoader(true));

      const filesToSave: IFileSavePayload[] = [];
      const filePropertyNames: string[] = [];
      for (let property of properties) {
        if (property?.custom?.meta == null) {
          let propertyValue = propertyValues?.properties[property?.name] || "";
          let propertyName = property.name;

          if (property?.custom?.schema?.defaultValue === "TODAY") {
            const momentLocal = moment(
              propertyValues?.properties[propertyName]
            );
            const utcDate = momentLocal.utc();
            propertyValues.properties[propertyName] = utcDate;
          }

          if (["name"].includes(propertyName)) {
            if (!validateReservedFields(propertyValue, propertyName)) return;
          }
          let validationRequest: IValidationRequest = {
            propertyName: propertyName,
            name: "properties." + propertyName,
            propertyValue: propertyValues?.properties[propertyName],
            propertyValueLen:
              propertyValues?.properties[propertyName]?.length || 0,
            min:
              property?.custom?.schema?.min !== null
                ? Number(property?.custom?.schema?.min)
                : null,
            max:
              property?.custom?.schema?.max !== null
                ? Number(property?.custom?.schema?.max)
                : null,
            regex:
              property?.custom?.schema?.regex !== null
                ? property?.custom?.schema?.regex
                : null,
            mandatory:
              property?.custom?.schema?.mandatory !== null
                ? property?.custom?.schema?.mandatory
                : "",
          };
          if (
            !FILE_TYPES.includes(property?.custom?.schema?.type) &&
            ![
              "name",
              "description",
              "createdAt",
              "updatedAt",
              "deleted",
            ].includes(propertyName)
          ) {
            if (
              property.type === "STRING" &&
              !validateString(validationRequest)
            ) {
              dispatch(seqrOpsActions.setLoader(false));
              setIsDisabled(false);
              return;
            }

            if (
              NUMERIC_TYPES.includes(property.type) &&
              !validateNumeric(validationRequest)
            ) {
              dispatch(seqrOpsActions.setLoader(false));
              setIsDisabled(false);
              return;
            }
          } else if (FILE_TYPES.includes(property?.custom?.schema?.type)) {
            if (!validateFiles(validationRequest)) {
              dispatch(seqrOpsActions.setLoader(false));
              setIsDisabled(false);
              return;
            }
            filePropertyNames.push(property.name);
            filesToSave.push({
              rid: filePreview[property.name].rid,
              instanceId: instance.rid,
              propertyName: property.name,
              reference: filePreview[property.name].ref,
              vertexName: instance.vertexName,
              fileURL: filePreview[property.name].fileURL,
              isChecked: false,
              isDeleted: false,
            });
          }
        }
      }

      let propertySavePayload: IPropertySavePayload = {
        properties: {},
        rid: instance.rid,
        type: instance.vertexName,
        files: filesToSave,
      };

      for (const key in propertyValues.properties) {
        if (filePropertyNames.includes(key)) {
          propertySavePayload.properties[key] = "";
        } else {
          propertySavePayload.properties[key] = propertyValues.properties[key];
        }
      }
      dispatch(seqrOpsActions.setLoader(false));
      setIsDisabled(false);

      // if(propertySavePayload.properties.dateTime !== undefined && propertySavePayload.properties.dateTime !== null ){
      //   let localDate = propertySavePayload.properties.dateTime;
      //   const utcDate = moment.utc(localDate).valueOf();
      //   propertySavePayload.properties.dateTime = utcDate;
      // }else{
      //   propertySavePayload.properties.dateTime = "";
      // }

      if (propertySavePayload?.properties?.description === undefined) {
        propertySavePayload.properties.description = "";
      }

      const result = await assetInstancePropertiesActions.saveInstanceProperty(
        propertySavePayload,
        filePreview,
        instance,
        setError
      );

      if (result) {
        dispatch(setIsDirty(false));
        setIsDisabled(false);
        await setSuccess();
      } else {
        dispatch(seqrOpsActions.setLoader(false));
        setIsDisabled(false);
      }
    } else {
      dispatch(seqrOpsActions.setLoader(true));
      setIsDisabled(true);
      let dynamicPropertySavePayloads: IDynamicPropertySavePayload[] = [];

      for (
        let index = 0;
        index < propertyValues.dynamicProperties.length;
        index++
      ) {
        let property: IDynamicProperty =
          propertyValues.dynamicProperties[index];
        console.log(property);
        let fieldName: string | any;

        if (property.deviceId !== 0 && property.deviceId !== undefined) {
          if (property.protocol === "") {
            fieldName = "dynamicProperties[" + index + "].protocol";

            dispatch(seqrOpsActions.setLoader(false));
            setIsDisabled(false);
            setError(fieldName, {
              type: "custom",
              message: MESSAGES.MANDATORY_FIELD_REQUIRED,
            });
            return;
          }
          if (property.protocol === "ModbusTCP" && property.ipAddress === "") {
            fieldName = "dynamicProperties[" + index + "].ipAddress";
            dispatch(seqrOpsActions.setLoader(false));
            setIsDisabled(false);
            setError(fieldName, {
              type: "custom",
              message: MESSAGES.MANDATORY_FIELD_REQUIRED,
            });
            return;
          }
          console.log("property : ", property);
          if (
            property.protocol === "ModbusTCP" &&
            property.ipAddress !== "" &&
            Number(property.port) === 0
          ) {
            fieldName = "dynamicProperties[" + index + "].port";
            dispatch(seqrOpsActions.setLoader(false));
            setIsDisabled(false);
            setError(fieldName, {
              type: "custom",
              message: MESSAGES.INVALID_PORT_NUMBER,
            });
            return;
          }
        } else if (property.deviceId > 0 && property.deviceId !== undefined) {
          if (property.protocol !== "" || property.ipAddress !== "") {
            fieldName = "dynamicProperties[" + index + "].deviceId";
            dispatch(seqrOpsActions.setLoader(false));
            setIsDisabled(false);
            setError(fieldName, {
              type: "custom",
              message: MESSAGES.MANDATORY_FIELD_REQUIRED,
            });
            return;
          } else {
            dispatch(seqrOpsActions.setLoader(false));
            setIsDisabled(false);
          }
        } else if (property.deviceId === undefined) {
          property.deviceId = 0;
          property.protocol = "";
          property.ipAddress = "";
        }

        dynamicPropertySavePayloads.push({
          rid: property.rid,
          instanceId: instance.rid,
          tagName: "",
          vertexName: instance.vertexName,
          propertyName: property.propertyName,
          deviceId: property.deviceId,
          protocol: property.protocol,
          ipAddress: property.ipAddress,
          port: property.port,
        });
      }

      console.log("dynamiccc : ", dynamicPropertySavePayloads);

      const result = await assetInstancePropertiesActions.saveDynamicProperty(
        dynamicPropertySavePayloads
      );
      if (result) {
        await setSuccess();
      } else {
        dispatch(seqrOpsActions.setLoader(false));
        setIsDisabled(false);
      }
    }
    setIsDisabled(false);
  };

  const validateReservedFields = (
    propertyValue: string,
    propertyName: string
  ) => {
    let isReservedFieldValid: boolean = true;
    let fieldName: string | any = "properties." + propertyName;

    isReservedFieldValid =
      propertyValue.length > 0 &&
      propertyValue !== null &&
      propertyValue !== " ";

    if (!isReservedFieldValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(fieldName, {
        type: "custom",
        message: MESSAGES.MANDATORY_FIELD_REQUIRED,
      });
      return false;
    } else {
      return true;
    }
  };

  const validateString = (validationRequest: IValidationRequest) => {
    let isMinValid: boolean = true;
    let isMaxValid: boolean = true;
    let isMandatoryValid: boolean = true;
    let isRegexValid: boolean = true;

    isMandatoryValid =
      validationRequest?.mandatory === "false"
        ? true
        : validationRequest?.mandatory !== null &&
          validationRequest?.propertyValue?.length <= 0
        ? false
        : true;
    if (!isMandatoryValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: MESSAGES.MANDATORY_FIELD_REQUIRED,
      });
    }

    isMinValid =
      validationRequest.min == null
        ? true
        : validationRequest.min !== null &&
          validationRequest.propertyValueLen < validationRequest.min
        ? false
        : true;
    if (!isMinValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message:
          "Should contain minimum " + validationRequest.min + " charecters",
      });
    }

    isMaxValid =
      validationRequest.max == null
        ? true
        : validationRequest.max !== null &&
          validationRequest.propertyValueLen > validationRequest.max
        ? false
        : true;
    if (!isMaxValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: "Max allowed number of charecters is " + validationRequest.max,
      });
    }

    if (validationRequest.regex !== null && validationRequest.regex !== "") {
      let regex = new RegExp(validationRequest.regex);
      isRegexValid = regex.test(validationRequest.propertyValue);
    }
    if (!isRegexValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: MESSAGES.REGEX_ERROR,
      });
    }
    if (isMandatoryValid && isMinValid && isMaxValid && isRegexValid) {
      return true;
    } else {
      dispatch(seqrOpsActions.setLoader(false));
      return false;
    }
  };

  const validateNumeric = (validationRequest: IValidationRequest) => {
    let isMinValid: boolean = true;
    let isMaxValid: boolean = true;
    let isMandatoryValid: boolean = true;
    let isRegexValid: boolean = true;
    let isTypeValid: boolean = true;

    // isTypeValid = isNaN(validationRequest.propertyValue)? false : true;
    // if (!isTypeValid) {
    //   dispatch(seqrOpsActions.setLoader(false));
    //   setError(validationRequest.name, {
    //     type: "custom",
    //     message: MESSAGES.NUMERIC_CONVENTION_ERROR,
    //   });
    // }

    isMandatoryValid =
      validationRequest.mandatory !== null &&
      String(validationRequest.propertyValue) !== "";
    if (!isMandatoryValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: MESSAGES.MANDATORY_FIELD_REQUIRED,
      });
    }
    if (validationRequest.min !== null) {
      isMinValid =
        Number(validationRequest.propertyValue) >=
        Number(validationRequest.min);
    }

    if (!isMinValid && validationRequest.min !== null) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: "Min must be greater than " + validationRequest.min,
      });
    }
    console.log(validationRequest.max !== null);

    if (validationRequest.max !== null) {
      isMaxValid =
        Number(validationRequest.propertyValue) <=
        Number(validationRequest.max);
      console.log(isMaxValid);
    }

    if (!isMaxValid && validationRequest.max !== null) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: "Max must be lesser than " + validationRequest.max,
      });
    }
    if (validationRequest.regex !== null && validationRequest.regex !== "") {
      isRegexValid = validationRequest.regex.text(
        validationRequest.propertyValue
      );
    }
    if (!isRegexValid) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(validationRequest.name, {
        type: "custom",
        message: MESSAGES.REGEX_ERROR,
      });
    }

    if (isMandatoryValid && isMinValid && isMaxValid && isRegexValid) {
      return true;
    } else {
      dispatch(seqrOpsActions.setLoader(false));
      return false;
    }
  };

  const validateFiles = (validationRequest: IValidationRequest) => {
    const name = validationRequest.name;
    const file = validationRequest.propertyValue?.[0] as File;
    const _filePreview = filePreview[validationRequest.propertyName];

    const hasOnlyPreview = !_filePreview.isDeleted && _filePreview.fileURL;
    const fileType = _filePreview.file.schemaType;

    if (hasOnlyPreview) {
      return true;
    }

    if (!file && validationRequest.mandatory === "true") {
      // No file selected, but required
      dispatch(seqrOpsActions.setLoader(false));
      setError(name, {
        type: "custom",
        message: MESSAGES.MANDATORY_FIELD_REQUIRED,
      });
      return false;
    }

    if (!file) {
      // No file selected
      return true;
    }

    if (!FILE_MIME_TYPES[fileType].includes(file.type)) {
      dispatch(seqrOpsActions.setLoader(false));
      setError(name, { type: "custom", message: MESSAGES.FILE_FORMAT_ERROR });
      return false;
    } else {
      if (fileType === "ICON") {
        if (file.size / (1024 * 1024) > 5) {
          dispatch(seqrOpsActions.setLoader(false));
          setError(name, {
            type: "custom",
            message: MESSAGES.FILE_SIZE_EXCEED_ERROR,
          });
          return false;
        }
      } else {
        if (file.size / (1024 * 1024) > 20) {
          dispatch(seqrOpsActions.setLoader(false));
          setError(name, {
            type: "custom",
            message: MESSAGES.FILE_SIZE_EXCEED_ERROR,
          });
          return false;
        }
      }
    }

    return true;
  };

  const handlePublish = () => {
    // dispatch(seqrOpsActions.setLoader(true));
    let publishPayload: publishPayload = {
      rid: rootInstance.rid,
    };
    dispatch(
      assetInstanceActions.publishInstance(
        publishPayload,
        rootInstance.vertexName,
        rootInstance.rid,
        setError
      )
    );
    // dispatch(seqrOpsActions.setLoader(false));
  };

  //  Dirty handling use Effects

  useEffect(() => {
    if (showStaticForm === "STATIC") {
      dispatch(setDynamicSwitch(true));
      dispatch(setStaticSwitch(false));
    } else if (showStaticForm === "DYNAMIC") {
      dispatch(setDynamicSwitch(false));
      dispatch(setStaticSwitch(true));
    }
  }, [showStaticForm]);

  useEffect(() => {
    if (switchItem === "STATIC") {
      setShowStaticForm("STATIC");
    } else if (switchItem === "DYNAMIC") {
      setShowStaticForm("DYNAMIC");
    }
  }, [switchItem, staticSwitch, dynamicSwitch]);

  return (
    <>
      <div className="seqrops-property-console-wrapper">
        <div className="container-fluid">
          <div className="seqrops-property-console-save-button-section d-flex justify-content-between ">
            <div className="div"></div>
            <div>
              <button
                onClick={handleSubmit(handleSave)}
                className="seqrops-property-save-btn sequrops_btn_primary btn-outline btn  me-2"
                disabled={isDisabled}
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => {
                  handlePublish();
                }}
                className="sequrops_btn_primary btn-primary btn"
              >
                Publish
              </button>
            </div>
          </div>
          <div className="property-console-head-section d-flex  justify-content-between bg-property-sec">
            <div className="seqrops-property-console-title-section">
              <h6>{instance.instance?.name}</h6>
            </div>
            <div className="seqrops-property-type-selection-section d-flex">
              <p
                className={`me-2 ${
                  showStaticForm === "STATIC" ? "active" : ""
                }`}
                onClick={() => {
                  // setShowStaticForm("STATIC");

                  console.log(isDirty);
                  // const queryParams = 'static-propery-form'
                  // history(`/?${queryParams}`);
                  if (isDirty === false) {
                    setDynamicPropertyFlag(false);
                    setStaticForm(false);
                    dispatch(setSwitchItem("STATIC"));
                    setShowStaticForm("STATIC");
                  } else {
                    dispatch(setDynamicSwitch(true));
                    dispatch(setStaticSwitch(false));
                  }
                }}
              >
                Static
              </p>
              <p
                onClick={() => {
                  // setShowStaticForm("DYNAMIC");

                  console.log(isDirty, staticSwitch);

                  if (isDirty === false) {
                    dispatch(setSwitchItem("DYNAMIC"));
                    setDynamicPropertyFlag(false);
                    setShowDynamicForm(false);
                    setShowStaticForm("DYNAMIC");
                  } else {
                    dispatch(setStaticSwitch(true));
                    dispatch(setDynamicSwitch(false));
                  }
                }}
                className={`me-2 ${
                  showStaticForm === "DYNAMIC" ? "active" : ""
                }`}
              >
                Dynamic
              </p>
            </div>
          </div>
          <div className="seqrops-property-form-section">
            <PropertyForm
              instance={instance}
              properties={properties}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              showStaticForm={showStaticForm === "STATIC"}
              filePreview={filePreview}
              handleFileChange={handleFileChange}
              getValues={getValues}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyConsole;
