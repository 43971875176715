import DialogProvider from "components/dialogs/DialogProvider";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "store";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Home from "./components/home.component/home";
import InstanceList from "./components/instance-list.component/instance-list";
import Navbar from "./components/navbar.component/navbar";
import InstanceConsole from "./modules/view-console/view-console";
import {
  ASSET_SUITE_LOGIN_URL,
  COOKIE_DETAILS,
  IFRAME_ID,
  KEYCLOAK_GRANT_TYPES,
  SESSION_KEY,
  SESSION_TYPE,
} from "config/default.config";
import { encryptUtils } from "utils/encryption.utils";
import { useEffect, useState } from "react";
import {
  ITokenDetails,
  IUserAuthorizations,
  IValidateTokenPayload,
} from "store/keycloak/asset-keycloak.interface";
import { validateToken } from "store/keycloak/asset-keycloak.action";
import { setLogin, setTheme } from "store/seqr-ops/seqr-ops.slice";
import { cookieUtils } from "utils/cookie.utils";
import MediaLogsList from "components/media-logs.component/media-logs-list.component/media-logs.list";
import { sessionStorageUtils } from "utils/session-storage.utils";

function App() {
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const [ready, setReady] = useState<boolean>(false);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [localThemeState, setLocalThemeState] = useState<string>("");
  const dispatch = useAppDispatch();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [loggedUser, setLoggedUser] = useState<string>();
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);

  useEffect(() => {
    const validateAuth = async () => {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;
      let encryptedCookieData = cookieUtils.getCookie(cookieName);
      let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
      if (decryptedCookieData?.themeType === "LIGHT") {
        dispatch(setTheme(false));
      } else if (decryptedCookieData?.themeType === "DARK") {
        dispatch(setTheme(true));
      }
      if (
        decryptedCookieData?.sessionId != null &&
        decryptedCookieData?.sessionId !== undefined &&
        decryptedCookieData?.sessionId !== ""
      ) {
        const validation = await validateToken(
          decryptedCookieData?.sessionId,
          setReady
        );
        if (!validation) {
          setReady(false);
          localStorage.clear();
          cookieUtils.deleteCookie(cookieName);
          // window.location.replace(ASSET_SUITE_LOGIN_URL);
        }
      } else if (
        decryptedCookieData?.sessionId == null ||
        decryptedCookieData?.sessionId === undefined ||
        decryptedCookieData?.sessionId === ""
      ) {
        setReady(false);
        localStorage.clear();
        cookieUtils.deleteCookie(cookieName);
        // window.location.replace(ASSET_SUITE_LOGIN_URL);
      }
    };

    //for setting username
    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      if (tokenData !== null) {
        setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
        setLoggedUser(tokenData?.token?.username);
      }
    }
    validateAuth();
  }, []);

  useEffect(() => {
    if (theme) {
      setLocalThemeState("dark");
    } else {
      setLocalThemeState("light");
    }
  }, [theme]);

  useEffect(() => {
    document.body.id = theme ? "dark" : "light";
  }, [theme]);

  if (!ready) {
    return null;
  }

  return (
    <div className="App" id={localThemeState}>
      <div className="seqrops-navbar-section">
        <Navbar
          loggeduser={
            userScopesFromLocalStorage !== undefined
              ? loggedUser
              : authorozedScopes.username
          }
        />
      </div>
      <DialogProvider>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/model-instance/:details"
            element={<InstanceList />}
          ></Route>
          <Route
            path="/instance/:details"
            element={<InstanceConsole />}
          ></Route>
          <Route
            path="/media-logs/:mediaDetails"
            element={<MediaLogsList />}
          ></Route>
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </DialogProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <div className="loader-row">
          <div className="loader">
            <div id="ld4">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
