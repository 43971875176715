import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { setRootInstance } from "store/asset-instance/asset-instance.slice";
import { encryptUtils } from "../../utils/encryption.utils";
import PropertyConsole from "../property-console/property-console";
import TreeConsole from "../tree-console/tree-console";
import "./view-console.scss";
import { assetModelActions } from "store/asset-models/asset-models.action";
import { ILogHistory } from "components/instance-list.component/instance-list.interface";
import { StringLiteral } from "typescript";
import { setImageLogs, setVideoLogs } from "store/asset-models/asset-models.slice";

function ViewConsole() {
  const dispatch = useAppDispatch();
  const { details } = useParams();
  const navigate = useNavigate();
  const imageMediaHistoryLogs = useAppSelector(
    (state) => state.assetModels.imageLogs
  );
  const videoMediaHistoryLogs = useAppSelector(
    (state) => state.assetModels.videoLogs
  );
  const [imageLog, setImageLog] = useState<ILogHistory[]>([]);
  const [videoLog, setVideoLog] = useState<ILogHistory[]>([]);
  const [instance, setInstance] = useState<{
    instanceId: string;
    currentModel: string;
  }>({
    instanceId: "",
    currentModel: "",
  });

  // const handleNavigateBack = () =>{
  //   dispatch(setImageLogs([]));
  //   setImageLog([]);
  //   setVideoLog([]);
  //   dispatch(setVideoLogs([]));
  // }

  const getDeviceMediaLogs = async (modelName: string, equipmentId: string) => {
    dispatch(await assetModelActions.getDeviceImei(modelName, equipmentId));
  };

  useEffect(() => {
    async function fetchData(reqBody: { rid: string; modelName: string }) {
      const details = await assetInstanceActions.getInstanceDetails(reqBody);
      if (details) {
        dispatch(setRootInstance({ ...details.data.data[0], edges: [] }));
      }
    }
    const data = encryptUtils.decryptURL(details);

    getDeviceMediaLogs(data?.modelName, data?.id);

    if (data) {
      setInstance({
        instanceId: data.id,
        currentModel: data.modelName,
      });
      fetchData({
        rid: data.id,
        modelName: data.modelName,
      });
    }
  }, [details]);

  useEffect(() => {
    if (imageMediaHistoryLogs.length > 0) {
      setImageLog(imageMediaHistoryLogs);
    }

    if (videoMediaHistoryLogs.length > 0) {
      setVideoLog(videoMediaHistoryLogs);
    }
  }, [imageMediaHistoryLogs, videoMediaHistoryLogs]);

  return (
    <>
      <div className="seqrops-instance-console-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <TreeConsole
                modelDetails={details}
                imageLog={imageLog}
                videoLog={videoLog}
                instanceId={instance.instanceId}
                modelName={instance.currentModel}
              />
            </div>
            <div className="col-md-5">
              <PropertyConsole />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewConsole;
